<script>
import Footer from "@/components/navigation/Footer";
import store from "@/store";
import FileUploader from "@/components/form-components/FileUploader";

export default {
  name: "PaymentSuccess",

  components: {
    Footer,
    FileUploader,
    personal: () =>
      import(/* webpackChunkName: "Dashboard" */ "../components/Personal.vue"),
    history: () =>
      import(/* webpackChunkName: "Dashboard" */ "../components/History.vue"),
    patients: () =>
      import(/* webpackChunkName: "Dashboard" */ "../components/Patients.vue"),
    security: () =>
      import(/* webpackChunkName: "Dashboard" */ "../components/Security.vue"),
  },

  data: () => ({
    verificationStep: 2,
    user: {
      name: "",
      email: "",
    },
    profileId: "",
    dialog: false,
    scrolled: false,
    notScrolled: true,
    avatar: null,
    showMenu: false,
    x: 0,
    y: 0,
    loading: false,
    credentials: store.getters["authentication/credentials"],
  }),

  computed: {
    currentComponent() {
      return this.$options.components?.[this.$route.params.action];
    },
  },

  async beforeMount() {
    this.scroll();
    await this.getData();
  },

  methods: {
    scroll() {
      window.addEventListener("scroll", () => {
        const top = window.pageYOffset;

        this.notScrolled = top === 0;
        this.scrolled = top > 250;
      });
    },
    getAvatar() {
      const credentials = JSON.parse(localStorage.getItem("credentials"));
      if (!credentials) {
        return null;
      }
      return credentials.user.avatar ? credentials.user.avatar.url : null;
    },
    async changeAvatar() {
      try {
        const credentials = this.$store.getters["authentication/credentials"];
        await this.$API
          .profile()
          .changeData(Number(credentials.user.id), { avatar: this.avatar });
        this.dialog = false;
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_edit")
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async getData() {
      try {
        this.user = await this.$API.profile().getPersonalData();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async logout() {
      try {
        await this.$store.dispatch("authentication/logout");
        document.location.href = "/ro/auth/login";
      } catch (e) {
        await this.$store.dispatch("alerts/showError", e.message);
      }
    },
  },
};
</script>

<template>
  <div>
    <v-container
      class="fill-height pa-6 pt-15 mt-2 mx-auto"
      style="max-width: 1200px"
    >
      <div class="grid-container">
        <div>
          <v-card
            elevation="2"
            class="fixed"
            :class="{ 'fixed-notscrolled': !scrolled }"
          >
            <v-card-text>
              <div class="text-center position-relative avatar">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-avatar
                      size="148"
                      color="primary"
                      class="user-avatar"
                      style="border: 4px solid"
                      v-on="on"
                      v-bind="attrs"
                      @click="dialog = true"
                    >
                      <img v-if="getAvatar()" :src="getAvatar()" />
                      <span
                        v-if="!getAvatar()"
                        class="white--text text-h4 font-weight-bold"
                      >
                        {{
                          user.name
                            .split(" ")
                            .map(([item]) => item)
                            .slice(0, 2)
                            .join("")
                            .toUpperCase()
                        }}
                      </span>
                      <div class="user-camera" v-if="!getAvatar">
                        <v-icon size="36">mdi-camera</v-icon>
                      </div>
                    </v-avatar>
                  </template>
                  <div>Изменить аватар</div>
                </v-tooltip>
                <v-menu
                  v-model="showMenu"
                  :position-x="x"
                  :position-y="y"
                  absolute
                  offset-y
                  class="pa-0 ma-0"
                >
                  <v-list dense class="py-0">
                    <v-list-item link @click="dialog = true">
                      <v-list-item-title>
                        <v-icon small class="mr-2">mdi-pencil</v-icon>
                        <span> Изменить аватар </span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <v-icon small class="mr-2">mdi-delete</v-icon>
                        <span> Удалить аватар </span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div class="font-weight-bold body-1 text-center mt-8">
                {{ user.name }}
              </div>
              <div class="body-2 text-center mb-2">{{ user.email }}</div>
            </v-card-text>
            <v-list class="pr-3" shaped>
              <v-list-item :to="`/profile/tab/personal`" color="primary">
                <v-list-item-icon>
                  <v-icon>mdi-card-account-details-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <span v-on="on" v-bind="attrs">
                          {{ $t("profile.headers.title") }}
                        </span>
                      </template>
                      <span> {{ $t("profile.headers.title") }} </span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="credentials.user.position === 'patient'"
                :to="`/profile/tab/history`"
                color="primary"
              >
                <v-list-item-icon>
                  <v-icon>mdi-clock-time-eight-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ $t("profile.headers.history") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="credentials.user.position === 'doctor'"
                :to="`/profile/tab/patients`"
                color="primary"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("profile.headers.patients") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!--              <v-list-item :to="`/profile/tab/sharing`" color="primary">-->
              <!--                <v-list-item-icon>-->
              <!--                  <v-icon>mdi-shield-account</v-icon>-->
              <!--                </v-list-item-icon>-->
              <!--                <v-list-item-content>-->
              <!--                  <v-list-item-title>Персональные данные</v-list-item-title>-->
              <!--                </v-list-item-content>-->
              <!--              </v-list-item>-->
              <v-list-item :to="`/profile/tab/security`" color="primary">
                <v-list-item-icon>
                  <v-icon>mdi-shield-account</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("profile.headers.security") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <template>
                <v-divider class="my-5 mr-2"></v-divider>
                <v-list-item @click="logout" color="primary">
                  <v-list-item-icon>
                    <v-icon>mdi-exit-to-app</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("global_buttons.exit") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </div>
        <div>
          <v-fade-transition leave-absolute hide-on-leave>
            <component :user="user" :key="1" :is="currentComponent"></component>
          </v-fade-transition>
        </div>
      </div>
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card v-if="dialog">
          <v-card-title>
            <span class="headline">Изменить аватар</span>
          </v-card-title>
          <v-card-text class="py-0">
            <v-container>
              <v-row>
                <v-col cols="12" class="py-0">
                  <file-uploader v-model="avatar" label="Прикрепить файл" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined text @click="dialog = false">
              {{ $t("global_buttons.close") }}
            </v-btn>
            <v-btn
              :disabled="!avatar || loading"
              color="primary"
              depressed
              @click="changeAvatar"
            >
              {{ $t("global_buttons.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  height: 100%;
  grid-column-gap: 20px;
  grid-template-columns: 300px 1fr;
}

.fixed {
  position: sticky;
  top: 100px;
  transition: 0.3s;
}

.fixed-notscrolled {
  top: 132px;
  position: sticky;
}

.user-avatar {
  cursor: pointer;

  .user-camera {
    display: none;
  }

  &:hover {
    span:first-child {
      display: none;
    }

    .user-camera {
      display: block;
    }
  }
}

.delete-icon {
  position: absolute;
  visibility: hidden;
  bottom: 0;
  right: 0;
  z-index: 1111111 !important;
}

.new-avatar {
  position: absolute;
  visibility: hidden;
  bottom: 0;
  z-index: 2111111111;
  height: 100%;
  left: 0;
  background-color: var(--v-primary-base);
  color: #fff;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
