<script>
import { mask } from "vue-the-mask";
import store from "@/store";

export default {
  name: "Share",

  directives: { mask },

  data: () => ({
    model: {},
  }),

  beforeMount() {
    this.getData();
  },

  methods: {
    async getData() {
      try {
        this.model = await this.$API
          .profile()
          .getInvestigation(this.$route.params.id);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    getStudyURL(cardDate) {
      let id = this.model.patient_idnp ?? this.model.patient_identity_card;
      const [date] = cardDate.split(" ");
      const [day, month, year] = date.split(".");

      const isoDate = `${year}-${month}-${day}`;
      return `jnlp://188.244.30.230:8585/weasis-pacs-connector/viewer?patientID=${id}&lowerDateTime=${isoDate}T23:59:59&upperDateTime=${isoDate}T00:00:01`;
    },
  },
};
</script>

<template>
  <v-container class="d-flex justify-center">
    <v-card elevation="1" class="pt-15" width="800px">
      <v-card-title class="bordered d-flex">
        <span>Сервис</span>
        <span class="ml-2">{{ model.service }}</span>
        <v-spacer></v-spacer>
        <span>{{ model.price }}MDL</span>
      </v-card-title>
      <v-card-subtitle class="d-flex">
        <span>Информация про сервис #{{ model.number }} </span>
        <v-spacer></v-spacer>
        <span>{{ model.payment_type }}</span>
      </v-card-subtitle>
      <v-card-text class="mt-3">
        <strong class="d-block subtitle-1">
          <v-icon>mdi-account</v-icon>
          <span class="ml-1">Imagist:</span>
          <span v-if="model.imagist"> {{ model.imagist }}</span>
          <span v-else class="ml-3">-</span>
        </strong>
        <strong class="d-block mt-2 subtitle-1">
          <v-icon>mdi-clipboard-account-outline</v-icon>
          <span>Technician:</span>
          <span v-if="model.technician"> {{ model.technician }}</span>
          <span v-else class="ml-3">-</span>
        </strong>
        <strong class="d-block mt-2 subtitle-1">
          <v-icon>mdi-file-pdf</v-icon>
          <span>Description:</span>
          <span v-if="model.description">
            <v-btn
              v-for="(description_link, index) in model.description"
              :href="description_link"
              title="Descriere"
              target="_blank"
              class="ml-2"
              :key="index"
              color="primary"
              fab
              x-small
              darl
            >
              <v-icon>mdi-newspaper-variant-outline</v-icon>
            </v-btn>

            <v-btn
              title="Vezi investigație"
              color="primary"
              class="ml-2"
              :href="model.investigation_url"
              target="_blank"
              fab
              x-small
              darl
            >
              <v-icon>mdi-television</v-icon>
            </v-btn>
          </span>
          <span v-else class="ml-3">-</span>
        </strong>
        <strong class="d-block mt-3 subtitle-1">
          <span>Referral Letter:</span>
          <v-btn
            v-if="model.doctor_referral_letter"
            title="Vezi îndreptare"
            class="ml-2"
            color="primary"
            :href="model.doctor_referral_letter.url"
            target="_blank"
            fab
            x-small
            darl
          >
            <v-icon>mdi-playlist-check</v-icon>
          </v-btn>
          <span v-else class="ml-3">-</span>
        </strong>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style lang="scss" scoped>
#pie-chart::v-deep .apexcharts-tooltip-series-group {
  background: rgba(0, 0, 0, 0.75) !important;
}
svg {
  fill: var(--v-primary-base);
}
.bordered {
  position: relative;

  &:before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: var(--v-primary-base);
    content: " ";
    height: 30px;
    width: 2px;
    border-radius: 50px !important;
  }
}
.pie-list {
  li:not(:last-child) {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
}
.comments-container > div {
  &:hover {
    background: var(--v-greyish-base);
  }
  .status-container {
    .v-icon {
      &:after {
        opacity: 0;
      }
      &.edit-icon {
        &:hover {
          color: var(--v-primary-base);
        }
      }
      &.remove-icon {
        &:hover {
          color: var(--v-error-base);
        }
      }
    }
  }
}
.card-container::v-deep {
  .fb {
    background: #5158d8;
  }
  *:not(.v-btn span) {
    color: #fff;
  }
}
</style>
